<template>
  <div class="content">
    <div
      :id="`double-slider-${id}`"
      :se-min="minThreshold"
      :se-step="step"
      :se-min-value="min"
      :se-max-value="max"
      :se-max="maxThreshold"
      class="slider"
    >
      <div class="slider-touch-left">
        <div class="slider-touch-indicator">{{ min }} Go</div>
        <span></span>
      </div>
      <div class="slider-touch-right">
        <div class="slider-touch-indicator">{{ max }} Go</div>
        <span></span>
      </div>
      <div class="slider-line">
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
import RangeSlider from './RangeSlider';

export default {
  name: 'DoubleSliderComponent',
  props: {
    id: {
      type: String,
      default: Date.now(),
    },
    minThreshold: {
      type: Number,
      default: -100,
    },
    maxThreshold: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      instance: undefined,
    };
  },
  mounted: function () {
      if (this.min > this.maxThreshold) {
          this.updateValues(2, this.max);
      }
      if (this.max > this.maxThreshold) {
          this.updateValues(this.min, this.maxThreshold);
      }
    this.instance = new RangeSlider('double-slider-' + this.id);
    this.instance.onChange = (min, max) => this.updateValues(min, max);
  },
  methods: {
    updateValues: function (min, max) {
      this.$emit('update:min', min);
      this.$emit('update:max', max);
      this.$emit('change');
    },
  },
};
</script>

<style lang="scss" scoped>
.slider {
  display: block;
  position: relative;
  height: 36px;
  width: 100%;
  user-select: none;
}
.slider .slider-touch-left,
.slider .slider-touch-right {
  box-sizing: border-box;
  display: block;
  position: absolute;
  height: 16px;
  width: 16px;
  margin-top: 12px;
  padding: 0;
  z-index: 2;
}
.slider .slider-touch-left span,
.slider .slider-touch-right span {
  display: block;
  width: 100%;
  height: 100%;
  background: #ff5c00;
  border: 2px solid #ffffff;
  border-radius: 50%;
}
.slider .slider-touch-left .slider-touch-indicator,
.slider .slider-touch-right .slider-touch-indicator {
  position: absolute;
  top: -22px;
  left: -10px;
  width: 40px;
  color: #ffffff !important;
  font-weight: bold;
  text-align: center;
}
.slider .slider-line {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 16px;
  height: 9px;
  border-radius: 4px;
  background: #ffffff;
  z-index: 0;
  overflow: hidden;
}
.slider .slider-line span {
  display: block;
  transform: translateX(8px);
  height: 100%;
  width: 0;
  background: #ff5c00;
}
</style>
