
import { defineComponent, ref, inject, watch, onBeforeMount } from 'vue';

export default defineComponent({
  name: 'TabComponent',
  setup() {
    const index = ref(0);
    const isActive = ref(false);

    const tabs: any = inject('TabsProvider');

    watch(
      () => tabs.selectedIndex,
      () => {
        isActive.value = index.value === tabs.selectedIndex;
      }
    );

    onBeforeMount(() => {
      index.value = tabs.count;
      tabs.count++;
      isActive.value = index.value === tabs.selectedIndex;
    });
    return { index, isActive };
  },
});
