
import { defineComponent, reactive, provide, onMounted, Fragment, toRefs } from 'vue';
import PaladiumIcon from '@/ui/components/bases/icons/PaladiumIcon.vue';
import PalanarchyIcon from '@/ui/components/bases/icons/PalanarchyIcon.vue';
import ModdedIcon from '@/ui/components/bases/icons/ModdedIcon.vue';
import MinecraftIcon from '@/ui/components/bases/icons/MinecraftIcon.vue';
import FuzeEventIcon from '@/ui/components/bases/icons/FuzeEventIcon.vue';
import HomeIcon from '@/ui/components/bases/icons/HomeIcon.vue';
import AvatarIcon from '@/ui/components/bases/icons/AvatarIcon.vue';
import ArchiveIcon from '@/ui/components/bases/icons/ArchiveIcon.vue';
import { TabsState } from '../../../typings/tabs';

export default defineComponent({
  name: 'TabsComponent',
  components: {
    PaladiumIcon,
    PalanarchyIcon,
    ModdedIcon,
    MinecraftIcon,
    FuzeEventIcon,
    HomeIcon,
    AvatarIcon,
    ArchiveIcon,
  },
  props: {
    defaultSelectTab: {
      type: [String, Number],
      default: 0,
    },
  },
  setup(props, { slots }) {
    const state: TabsState = reactive({
      selectedIndex: 0,
      tabs: [],
      count: 0,
    });

    provide('TabsProvider', state);

    const selectTab = (i: number) => {
      state.selectedIndex = i;
    };

    onMounted(() => {
      if (slots.default) {
        let tabsArray: Array<any> = [];
        slots.default().forEach(child => {
          const ftype: any = child.type;
          const fchild: any = child.children;
          if (ftype.name === 'TabComponent') {
            tabsArray.push(child);
          } else if (child.type === Fragment) {
            if (fchild && fchild.length > 0) {
              fchild.forEach((subChild: any) => {
                tabsArray.push(subChild);
              });
            }
          }
        });

        state.tabs = tabsArray;
      }

      if (props.defaultSelectTab) {
        if (typeof props.defaultSelectTab === "number")
          selectTab(props.defaultSelectTab);
        else {
          const id = state.tabs.findIndex((tab) => {
            return tab.props.id === props.defaultSelectTab;
          });
          selectTab(id === -1 ? 0 : id);
        }
      }
    });

    return { ...toRefs(state), selectTab };
  },
});
