
import Titlebar from '@/ui/components/Titlebar.vue';
import Navigation from '@/ui/components/navigation/Navigation.vue';
import SettingsModal from '@/ui/components/modals/SettingsModal.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    Titlebar,
    Navigation,
    SettingsModal,
  },
  data() {
    return {
      settingsModalIsOpen: false,
      settingsModalDefaultTab: '',
    };
  },
    methods: {
    openSettingsModal(val:any) {
      this.settingsModalIsOpen = true;
      this.settingsModalDefaultTab = val;
    }
  }
});
