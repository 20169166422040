import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c87d3acc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notification" }
const _hoisted_2 = {
  key: 0,
  class: "notification-unread"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArchiveIcon = _resolveComponent("ArchiveIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "notification-item",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickLink && _ctx.clickLink(...args)))
    }, [
      _createElementVNode("h3", null, [
        _createTextVNode(_toDisplayString(_ctx.name) + " ", 1),
        (_ctx.isRead === false)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("p", null, _toDisplayString(_ctx.description), 1)
    ]),
    (!_ctx.archived)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clickArchive && _ctx.clickArchive(...args)))
        }, [
          _createVNode(_component_ArchiveIcon)
        ]))
      : _createCommentVNode("", true)
  ]))
}