import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3823549a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "pala-item--notification__icon-wrapper"
}
const _hoisted_2 = {
  key: 2,
  class: "pala-item--notification__activity-indicator"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SuccessIcon = _resolveComponent("SuccessIcon")!
  const _component_ErrorIcon = _resolveComponent("ErrorIcon")!
  const _component_PalaCloseButton = _resolveComponent("PalaCloseButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["pala-item pala-item--notification", [`pala-item--notification--${_ctx.state}`]])
  }, [
    (!_ctx.hideIcon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.state === _ctx.STATE.SUCCESS)
            ? (_openBlock(), _createBlock(_component_SuccessIcon, {
                key: 0,
                class: "pala-item--notification__icon"
              }))
            : (_ctx.state === _ctx.STATE.ERROR)
              ? (_openBlock(), _createBlock(_component_ErrorIcon, {
                  key: 1,
                  class: "pala-item--notification__icon"
                }))
              : (_ctx.state === _ctx.STATE.LOADING)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2))
                : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (!_ctx.hideCloseButton)
      ? (_openBlock(), _createBlock(_component_PalaCloseButton, {
          key: 1,
          class: "pala-item--notification__close-button",
          onClick: _ctx.requestDismiss
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ], 2))
}