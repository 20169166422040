
//CEF Request for Getter
export function requestGetter(name: string, payload: any, onSuccess: any) {
    const w: any = window;

    if (!w.cefQuery) {
        //Clean display error code and message
        console.error("CEF ERROR: CEF is not available");
    } else {
        w.cefQuery({
            request: JSON.stringify({
                type: "GETTER",
                name: name,
                payload: payload,
            }),
            persistent: false,
            onSuccess: (response: any) => {
                let data: any;
                try {
                    data = JSON.parse(response);
                } catch (e) {
                    //print error parsing json and response
                    console.error("CEF ERROR: " + e + " - " + response);
                }
                if (onSuccess) {
                    onSuccess(data);
                }
            },
            onFailure: function (error_code: any, error_message: any) {
                //Clean display error code and message
                console.error("CEF ERROR: " + error_code + " - " + error_message);
            }
        });
    }
}

//CEF RRquest for Setter
export function requestSetter(name: string, payload: any, onSuccess: any) {
    const w: any = window;

    if (!w.cefQuery) {
        //Clean display error code and message
        console.error("CEF ERROR: CEF is not available");
    } else {
        w.cefQuery({
            request: JSON.stringify({
                type: "SETTER",
                name: name,
                payload: payload
            }),
            persistent: false,
            onSuccess: function (response: any) {
                let data: any;
                try {
                    data = JSON.parse(response);
                } catch (e) {
                    //print error parsing json and response
                    console.error("CEF ERROR: " + e + " - " + response);
                }
                if (onSuccess) {
                    onSuccess(data);
                }
            },
            onFailure: function (error_code: any, error_message: any) {
                //Clean display error code and message
                console.error("CEF ERROR: " + error_code + " - " + error_message);
            }
        });
    }
}

//CEF Request for Action
export function requestAction(name: string, payload: any, onSuccess: any) {
    const w: any = window;

    if (!w.cefQuery) {
        //Clean display error code and message
        console.error("CEF ERROR: CEF is not available");
    } else {
        w.cefQuery({
            request: JSON.stringify({
                type: "ACTION",
                name: name,
                payload: payload
            }),
            persistent: false,
            onSuccess: function (response: any) {
                let data: any;
                try {
                    data = JSON.parse(response);
                } catch (e) {
                    //print error parsing json and response
                    console.error("CEF ERROR: " + e + " - " + response);
                }
                if (onSuccess) {
                    onSuccess(data);
                }
            },
            onFailure: function (error_code: any, error_message: any) {
                //Clean display error code and message
                console.error("CEF ERROR: " + error_code + " - " + error_message);
            }
        });
    }
}
