
import { defineComponent } from 'vue';
import Titlebar from '@/ui/components/Titlebar.vue';

export default defineComponent({
  name: 'EmptyLayout',
  components: {
    Titlebar,
  },
});
