
import { defineComponent } from 'vue';

/**
 * @enum {string}
 * @readonly
 */
const Tag = {
  LINK: 'a',
  BUTTON: 'button',
  ROUTER_LINK: 'RouterLink',
};

/**
 * @enum {string}
 * @readonly
 */
export const Target = {
  SELF: '_self',
  BLANK: '_blank',
};

/**
 * @enum {string}
 * @readonly
 */
export const Rel = {
  NO_OPENER: 'noopener',
  NO_REFERRER: 'noreferrer',
};

const targetValidator = (value: string) => !value || Object.values(Target).indexOf(value) !== -1;

export default defineComponent({
  name: 'PalaLink',
  props: {
    disabled: Boolean,
    href: { type: String },
    to: { type: Object },
    target: { type: String, default: Target.SELF, validator: targetValidator },
    noForwardOpener: Boolean,
    noForwardReferrer: Boolean,
  },
  computed: {
    relAttr() {
      const relAttrValueList = [];

      if (this.href && this.target === Target.BLANK) {
        if (this.noForwardReferrer) {
          relAttrValueList.push(Rel.NO_REFERRER);
        }

        if (this.noForwardOpener) {
          relAttrValueList.push(Rel.NO_OPENER);
        }
      }

      return (relAttrValueList.length && relAttrValueList.join(' ')) || undefined;
    },
    tag() {
      if (this.to) {
        return Tag.ROUTER_LINK;
      }
      if (this.href) {
        return Tag.LINK;
      }
      return Tag.BUTTON;
    },
  },
});
