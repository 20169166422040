import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-488ef73f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "expander" }
const _hoisted_2 = { class: "icon" }
const _hoisted_3 = {
  key: 0,
  class: "expander-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowDownIcon = _resolveComponent("ArrowDownIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "expander-header",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isExpanded = !_ctx.isExpanded))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.name), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.description), 1)
      ]),
      _createVNode(_component_ArrowDownIcon, { class: "arrow-icon" })
    ]),
    (_ctx.isExpanded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ]))
}