
import { defineComponent } from 'vue';
import { getVersion, getIsUnderMaintenance, checkForUpdates, onUpdateAvailable } from '../../electron';

import DangerIcon from '@/ui/components/bases/icons/DangerIcon.vue';
import UpdateIcon from '@/ui/components/bases/icons/UpdateIcon.vue';
import PalaLink from '../components/bases/PalaLink.vue';
import {requestAction, requestGetter} from "../../utils/cef";
import store from "../../store";
import * as Games from "../../domain/Games";
import {Game} from "../../typings/games";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default defineComponent({
  name: 'SplashPage',
  components: {
    PalaLink,
    DangerIcon,
    UpdateIcon,
    LottieAnimation,
  },
  data() {
    return {
      version: '1.0.0',
      isUnderMaintenance: false,
      isUnderUpdate: false,
    };
  },
  async beforeMount() {
    /*onUpdateAvailable(() => {
      this.isUnderUpdate = true;
    });*/

    /*this.version = await getVersion();
    this.isUnderMaintenance = await getIsUnderMaintenance();*/
  },
    methods: {
        clearanim() {
            let elements: HTMLCollection = document.getElementsByTagName('svg');
            for (var i = 0; i < elements.length; i++) {
                const e: any = elements[i];
                e.style.transform = '';
            }
        }
    },
  mounted() {
    //requestAction("RESIZE", {width: 616, height: 840}, undefined);
      requestGetter("IS_LOGGED", {},(logdata: any) => {
          if (logdata.status === 200) {
              requestGetter("GET_TOKEN", {}, (ttdata: any) => {
                  console.log(JSON.stringify(ttdata));
                  Games.getGames(ttdata.payload.jwt).then((games: Game[]) => {
                      if(store.state.games !== null) {
                          const gr: Game | undefined = store.state.games.at(0);
                          if (gr !== undefined)
                            this.$router.push(gr.id);
                      }
                  });
              });
          } else {
              this.$router.push("Login")
          }
      });
  },
});
