
import { defineComponent } from 'vue';
import BellIcon from '@/ui/components/bases/icons/BellIcon.vue';
import XIcon from '@/ui/components/bases/icons/XIcon.vue';
import Tab from '@/ui/components/tab/Tab.vue';
import Tabs from '@/ui/components/tab/Tabs.vue';
import NotificationItem from './NotificationItem.vue';

export default defineComponent({
  name: 'NotificationsModal',
  components: {
    BellIcon,
    XIcon,
    Tab,
    Tabs,
    NotificationItem,
  },
});
