import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21b5ca2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "game-page" }
const _hoisted_2 = { id: "game-page__logo" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = {
  key: 0,
  id: "game-page__content"
}
const _hoisted_5 = { class: "headline" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "description" }
const _hoisted_8 = ["aria-disabled"]
const _hoisted_9 = {
  key: 0,
  class: "progression"
}
const _hoisted_10 = { class: "progress-bar" }
const _hoisted_11 = { class: "progression-percentage" }
const _hoisted_12 = ["disabled", "title"]
const _hoisted_13 = ["disabled", "title"]
const _hoisted_14 = { class: "display-line" }
const _hoisted_15 = {
  key: 1,
  id: "game-page__footer"
}
const _hoisted_16 = { id: "game-page__footer__container" }
const _hoisted_17 = { class: "spotlight-news" }
const _hoisted_18 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorModal = _resolveComponent("ErrorModal")!
  const _component_PlayIcon = _resolveComponent("PlayIcon")!
  const _component_DownloadIcon = _resolveComponent("DownloadIcon")!
  const _component_PalaSelect = _resolveComponent("PalaSelect")!
  const _component_CogIcon = _resolveComponent("CogIcon")!
  const _component_Spotlight = _resolveComponent("Spotlight")!
  const _component_ShopCard = _resolveComponent("ShopCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, {
      appear: "",
      name: "translate-background",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.showAnimation.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              id: "game-page__background",
              style: _normalizeStyle(`background-image: url('${_ctx.data.backgroundImage}');`)
            }, null, 4))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_Transition, {
        appear: "",
        name: "translate-logo"
      }, {
        default: _withCtx(() => [
          (_ctx.showAnimation.value)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.data.logo,
                alt: _ctx.data.name,
                draggable: "false"
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    (_ctx.errorModal.isOpen)
      ? (_openBlock(), _createBlock(_component_ErrorModal, {
          key: 0,
          title: _ctx.errorModal.title,
          "primary-action": _ctx.errorModal.primaryButton,
          "secondary-action": _ctx.errorModal.secondaryButton,
          critical: _ctx.errorModal.critical,
          messages: _ctx.errorModal.messages,
          onDidRequestPrimaryAction: _cache[0] || (_cache[0] = () => { _ctx.errorModal.primaryAction === 'CLOSE' ? _ctx.errorModal.isOpen = false : _ctx.requestAction(_ctx.errorModal.primaryAction, null, {})  }),
          onDidRequestSecondaryAction: _cache[1] || (_cache[1] = () => { _ctx.errorModal.secondaryAction === 'CLOSE' ? _ctx.errorModal.isOpen = false : _ctx.requestAction(_ctx.errorModal.secondaryAction, null, {})  })
        }, null, 8, ["title", "primary-action", "secondary-action", "critical", "messages"]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, {
      appear: "",
      name: "translate-content",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.showAnimation.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.data.headline), 1),
              _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.data.name), 1),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.data.description), 1),
              _createElementVNode("div", {
                class: "actions",
                "aria-disabled": _ctx.gameState.launchable
              }, [
                (_ctx.gameState.progress !== null && _ctx.gameState.progress !== 100 && _ctx.gameState.progress !== 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", {
                          class: "progress-bar-value",
                          style: _normalizeStyle(`width: ${_ctx.gameState.progress}%`)
                        }, null, 4)
                      ]),
                      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.gameState.progress) + "% " + _toDisplayString(_ctx.gameState.downloadingSpeed), 1)
                    ]))
                  : (_ctx.gameState.installed)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        disabled: _ctx.gameState.startup || !_ctx.gameState.launchable,
                        title: _ctx.gameState.launchable ? '' : 'Ce service n\'est pas autorisé sur Linux',
                        type: "button",
                        class: "action-btn btn",
                        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.actionBtn && _ctx.actionBtn(...args)))
                      }, [
                        _createVNode(_component_PlayIcon),
                        _createTextVNode(" " + _toDisplayString(_ctx.gameState.startup ? 'Démarrage...' : 'Jouer'), 1)
                      ], 8, _hoisted_12))
                    : (_openBlock(), _createElementBlock("button", {
                        key: 2,
                        type: "button",
                        class: "action-btn btn",
                        disabled: !_ctx.gameState.launchable,
                        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.actionBtn && _ctx.actionBtn(...args))),
                        title: _ctx.gameState.launchable ? '' : 'Ce service n\'est pas autorisé sur Linux'
                      }, [
                        _createVNode(_component_DownloadIcon),
                        _createTextVNode(" Installer ")
                      ], 8, _hoisted_13))
              ], 8, _hoisted_8),
              _createElementVNode("div", _hoisted_14, [
                (_ctx.gameState.allVersions)
                  ? (_openBlock(), _createBlock(_component_PalaSelect, {
                      key: 0,
                      class: "select-version",
                      value: _ctx.gameState.selectedVersion,
                      options: _ctx.gameState.allVersions,
                      onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectedVersionChange($event)))
                    }, null, 8, ["value", "options"]))
                  : _createCommentVNode("", true),
                _createElementVNode("button", {
                  class: "settings-btn",
                  type: "button",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('openSettings', _ctx.data.id)))
                }, [
                  _createVNode(_component_CogIcon)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (!_ctx.data.hideFooter)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_Transition, {
              appear: "",
              name: "translate-spotlight-1",
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                (_ctx.showAnimation.value)
                  ? (_openBlock(), _createBlock(_component_Spotlight, {
                      key: 0,
                      title: "DERNIERES NEWS"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_17, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gameState.blogPosts, (post) => {
                            return (_openBlock(), _createElementBlock("a", {
                              key: post.id,
                              class: "spotlight-news-box",
                              href: `https://paladium-pvp.fr/articles/${post.slug}`,
                              target: "_blank",
                              rel: "noopener noreferrer",
                              style: _normalizeStyle(`background-image: url(${post.feature_image});`)
                            }, null, 12, _hoisted_18))
                          }), 128))
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            (_ctx.gameState.onlineConfig && _ctx.gameState.onlineConfig.shop)
              ? (_openBlock(), _createBlock(_Transition, {
                  key: 0,
                  appear: "",
                  name: "translate-spotlight-2",
                  mode: "out-in"
                }, {
                  default: _withCtx(() => [
                    (_ctx.showAnimation.value)
                      ? (_openBlock(), _createBlock(_component_Spotlight, {
                          key: 0,
                          title: "LA BOUTIQUE"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ShopCard, {
                              items: _ctx.gameState.onlineConfig.shop,
                              "accent-color": _ctx.data.colors.activeIndicator
                            }, null, 8, ["items", "accent-color"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}