import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), _mergeProps({ href: _ctx.href, to: _ctx.to, target: _ctx.target, disabled: _ctx.disabled, rel: _ctx.relAttr }, {
    class: "link",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', $event)))
  }), {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }, 16))
}