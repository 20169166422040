import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3832b3a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "shop-card" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "shop-card-container__img" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "shop-card-container__details" }
const _hoisted_6 = {
  key: 0,
  class: "subtitle"
}
const _hoisted_7 = { class: "price" }
const _hoisted_8 = { class: "shop-card-footer" }
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowRightIcon = _resolveComponent("ArrowRightIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: _ctx.items[_ctx.currentId].link,
      target: "_blank",
      rel: "noopener noreferrer",
      class: "shop-card-container"
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          alt: "product name",
          src: _ctx.items[_ctx.currentId].icon,
          draggable: "false"
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", null, [
          (_ctx.items[_ctx.currentId].subtitle)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.items[_ctx.currentId].subtitle), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "title",
            style: _normalizeStyle(`color: ${_ctx.items[_ctx.currentId].color};`)
          }, _toDisplayString(_ctx.items[_ctx.currentId].title), 5)
        ]),
        _createElementVNode("div", null, [
          (_ctx.items[_ctx.currentId].priceSubtitle)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(`subtitle ${ _ctx.subtitleType }`)
              }, _toDisplayString(_ctx.items[_ctx.currentId].priceSubtitle), 3))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.items[_ctx.currentId].price), 1)
        ])
      ])
    ], 8, _hoisted_2),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("button", {
        type: "button",
        class: "arrowBtn inverted",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setLeftItem && _ctx.setLeftItem(...args)))
      }, [
        _createVNode(_component_ArrowRightIcon)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("button", {
          type: "button",
          onClick: ($event: any) => (_ctx.setItem(index)),
          key: index,
          class: _normalizeClass('indicator' + (index === _ctx.currentId ? ' active' : ''))
        }, null, 10, _hoisted_9))
      }), 128)),
      _createElementVNode("button", {
        type: "button",
        class: "arrowBtn",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setRightItem && _ctx.setRightItem(...args)))
      }, [
        _createVNode(_component_ArrowRightIcon)
      ])
    ])
  ]))
}