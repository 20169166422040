import { createWebHashHistory, createRouter } from 'vue-router';

import Splash from '@/ui/pages/Splash.vue';
import Login from '@/ui/pages/Login.vue';
import GamePage from '@/ui/pages/GamePage.vue';
import Gamepass from "./ui/pages/Gamepass.vue";

const routes = [
  {
    path: '/splash',
    name: 'Splash',
    meta: { layout: 'empty', titlebar: true },
    component: Splash,
  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'empty', titlebar: true },
    component: Login,
  },
  {
    path: '/gamepass',
    name: 'GamePass',
    meta: { layout: 'empty', titlebar: false },
    component: Gamepass,
  },
  {
    path: '/:id',
    name: 'GamePage',
    meta: { titlebar: true },
    component: GamePage,
    props: true,
  },
  // Default path to redirect if not exist
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: '/paladium',
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
