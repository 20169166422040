
import { defineComponent } from 'vue';
import NavigationContainer from './NavigationContainer.vue';
import {requestGetter} from "../../../utils/cef";

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Avatar',
  components: { NavigationContainer },
  data() {
    return {
      avatar: `background-image: url("https://crafatar.com/skins/ec561538-f3fd-461d-aff5-086b22154bce")`,
      title: "unknown",
    };
  },
  mounted() {
      requestGetter("UUID", undefined, (data: any) => {
          if (data.status === 200) {
              this.avatar = `background-image: url("https://crafatar.com/skins/${data.payload.uuid}")`;
          }
      });

      requestGetter("NAME", undefined, (data: any) => {
          if (data.status === 200) {
              this.title = data.payload.name;
          }
      });
  },
  computed: {},
});
