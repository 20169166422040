<template>
  <div @click="changeAccount" :class="`user-card ${active ? 'active' : ''}`">
    <div class="user-card-header">
      <div class="avatar" :style="`background-image: url('${skinUrl}')`"></div>
      <div>
        <h4>{{ name }}</h4>
        <p>{{ description }}</p>
      </div>
      <div class="user-card-btn">
        <button type="button" @click.stop="logoutUser">
          <LogoutIcon />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LogoutIcon from '@/ui/components/bases/icons/LogoutIcon.vue';
import {requestAction} from "@/utils/cef";

export default {
  name: 'UserCard',
  components: {
    LogoutIcon,
  },
  props: {
    name: String,
    skinUrl: String,
    description: String,
    active: Boolean,
    mcuuid: String,
  },
  methods: {
    changeAccount() {
        requestAction("SWITCH_ACCOUNT", {uuid: this.mcuuid}, () => {
            this.$store.commit("setGames", null);
            this.$router.push("Splash");
        });
    },
    logoutUser() {
        requestAction("LOGOUT_ACCOUNT", {uuid: this.mcuuid}, () => {
            this.$store.commit("setGames", null);
            this.$router.push("Splash");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-card {
  background-color: #181818;
  border-radius: 6px;
  border: 1px solid #212121;
  width: 100%;
  padding: 0 20px;

  &.active {
    border-color: #FF5C00;
  }

  &:hover {
    background-color: #0C0C0C;
  }

  .user-card-header {
    height: 71px;
    width: 100%;

    display: flex;
    gap: 27px;
    align-items: center;
    justify-content: space-between;

    fill: #828282;
    color: #ffffff;
    cursor: pointer;

    .avatar {
      border-radius: 10px;
      transform: scale(0.8);

      image-rendering: optimizeSpeed;
      image-rendering: pixelated;
      -ms-interpolation-mode: nearest-neighbor;

      background-size: 512px;
      width: 64px;
      height: 64px;
      background-position: -64px -64px;
      overflow: hidden;
      display: inline-block;

      &::after {
        content: ' ';
        width: 64px;
        height: 64px;
        background-size: 576px;
        background-image: inherit;
        background-position: -364px -76px;
        overflow: hidden;
        display: inline-block;
      }
    }

    h4 {
      font-size: 20px;
      font-weight: bold;
    }

    p {
      margin-top: 4px;
      font-size: 15px;
      color: #828282;
    }

    .user-card-btn {
      margin-left: auto;

      svg {
        height: 23px;
      }
    }
  }
}
</style>
