
import ArrowDownIcon from '@/ui/components/bases/icons/ArrowDownIcon.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ExpanderComponent',
  components: {
    ArrowDownIcon,
  },
  props: {
    name: String,
    description: String,
  },
  data() {
    return {
      isExpanded: false,
    };
  },
});
