
import { defineComponent } from 'vue';
import { setNotificationArchive, setNotificationRead } from '../../../../electron';
import ArchiveIcon from '@/ui/components/bases/icons/ArchiveIcon.vue';

export default defineComponent({
  name: 'NotificationItem',
  components: {
    ArchiveIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    read: {
      type: Boolean,
      default: false,
    },
    archived: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isRead: false,
    };
  },
  async beforeMount() {
    this.isRead = this.read;
  },
  methods: {
    clickLink() {
      //setNotificationRead(this.id);
      this.isRead = true;
      if (this.link.startsWith('https://')) {
        // Open in browser
        window.open(this.link, '_blank');
      } else if (this.link.startsWith('#')) {
        this.$router.push(this.link.slice(1));
        this.$emit('close');
      }
    },
    clickArchive() {
      //setNotificationArchive(this.id);
    },
  },
});
