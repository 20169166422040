import { createStore } from 'vuex';
import {Game} from "./typings/games";

interface State {
  username: string;
  skinUrl: string;
  uuid: string;
  notifications: [];
  games: Game[] | null;
}

const store = createStore<State>({
  state() {
    return {
      uuid: '',
      username: '',
      skinUrl: '',
      notifications: [],
      games: null,
    };
  },
  mutations: {
    setUsername(state, val) {
      state.username = val;
    },
    setSkinUrl(state, val) {
      state.skinUrl = val;
    },
    setNotifications(state, val) {
      state.notifications = val;
    },
    setUuid(state, val) {
        state.uuid = val;
    },
    setGames(state, val) {
        state.games = val;
    }
  },
});

export default store;
