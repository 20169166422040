
import { defineComponent } from 'vue';
import Spinner from '@/ui/components/bases/Spinner.vue';
import {requestAction, requestGetter, requestSetter} from "../../utils/cef";

export default defineComponent({
  name: 'gamepassPage',
  components: { Spinner },
  data() {
    return {
      version: '1.0.0',
      ingamepass: false,
      pseudonyme: '',
    };
  },
  async beforeMount() {
    //this.version = await getVersion();

    // Listeners
    /*onSetgamepassBtn((value: boolean) => {
      this.ingamepass = value;
    });*/
  },
  methods: {
    gamepass() {
      this.ingamepass = true;
        requestSetter("GAMEPASS", {
          username: this.pseudonyme,
        }, () => {
          this.ingamepass = false;
        });
      //window.location.href = this.link;
    },
  },
});
