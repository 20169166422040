import { Game } from '../typings/games';
import axios, {AxiosResponse} from 'axios';
import store from '../store';

export async function getGames(token: string): Promise<Game[]> {
  const results: Game[] = new Array<Game>();

  if (store.state.games !== null) {
    return store.state.games;
  }
  const raw: AxiosResponse = await axios({
    url: 'https://palagames-launcher-front.pages.dev/api/games/listGames',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'authorization': "Bearer " + token,
    },
  });
  try {
    if (raw.status === 200) {
      const glist: string[] = raw.data;
      for (const g of glist) {
        const game: Game | null = await getGame(g, token);
        if (game) results.push(game);
      }
    } else {
      console.log(raw);
    }
  }catch (e: any) {
    console.log(e);
  }
  store.commit('setGames', results);
  return results;
}

export async function getGame(id: string, token: string): Promise<Game | null> {
  const raw: AxiosResponse = await axios({
    url: 'https://palagames-launcher-front.pages.dev/api/games/getGame',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'authorization': "Bearer " + token,
    },
    data: JSON.stringify({gameId: id}),
  });
  try {
    if (raw.status === 200) {
      return raw.data;
    } else {
      console.log(raw);
    }
  }catch (e: any) {
    console.log(e);
  }
  return null;
}
