
import { getVersion, getAccessToken, onSetLoginBtn } from '../../electron';
import { defineComponent } from 'vue';
import PalaLink from '@/ui/components/bases/PalaLink.vue';
import Spinner from '@/ui/components/bases/Spinner.vue';
import {requestAction, requestGetter} from "../../utils/cef";

export default defineComponent({
  name: 'LoginPage',
  components: { Spinner, PalaLink },
  data() {
    return {
      version: '1.0.0',
      inLogin: false,
      link: '',
    };
  },
  mounted() {
    //requestAction("RESIZE", {width: 616, height: 840}, undefined);
      requestGetter("GET_LOGIN_LINK", {},(data: any) => {
          this.link = data.payload.link;
      });
  },
  async beforeMount() {
    //this.version = await getVersion();

    // Listeners
    /*onSetLoginBtn((value: boolean) => {
      this.inLogin = value;
    });*/
  },
  methods: {
    login() {
      this.inLogin = true;
        requestAction("POPUP",
            {
                title: "Connexion",
                url: this.link,
                width: 536,
                height: 693,
            }, () => {
                this.inLogin = false;
            });
      //window.location.href = this.link;
    },
  },
});
