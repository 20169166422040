import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70e9a893"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs" }
const _hoisted_2 = { class: "tabs-header" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "badge"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass([{ 'tab-selected': index === _ctx.selectedIndex }, "tab"]),
          onClick: ($event: any) => (_ctx.selectTab(index))
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(tab.props['icon-name']))),
          _createTextVNode(" " + _toDisplayString(tab.props.name) + " ", 1),
          (tab.props.badge)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(tab.props.badge), 1))
            : _createCommentVNode("", true),
          (_openBlock(), _createBlock(_resolveDynamicComponent(tab.props['right-icon-name']), { class: "right-icon" }))
        ], 10, _hoisted_3))
      }), 128))
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}