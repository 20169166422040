<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="6.476" height="9.216" viewBox="0 0 6.476 9.216">
    <path d="M9,9.238l.809.809,2.429-2.429,2.428,2.429.81-.809L12.238,6Z" transform="translate(-9 -6)" fill="#303030" />
    <path
      d="M9,20.353l.809-.809,2.429,2.429,2.428-2.429.81.809-3.238,3.238Z"
      transform="translate(-9 -14.376)"
      fill="#303030"
    />
  </svg>
</template>

<script>
export default {
  name: 'SelectIcon',
};
</script>
