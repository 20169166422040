
import { defineComponent } from 'vue';
import ArrowRightIcon from '../bases/icons/ArrowRightIcon.vue';

const __default__ = defineComponent({
  name: 'ShopCard',
  props: [
    'items',
    'accentColor',
  ],
  components: {
    ArrowRightIcon,
  },
  data() {
    return {
      currentId: 0,
      activeColor: this.accentColor,
    };
  },
  computed: {
    subtitleType() {
      let str = this.items[this.currentId].priceSubtitle;
      if (str === '%') {
        return 'percentage-subtitle';
      } else if (str === '+') {
        return 'add-subtitle';
      } else {
        return '';
      }
    }
  },
  methods: {
    setLeftItem() {
      if (this.currentId - 1 >= 0)
        this.currentId--;
    },
    setRightItem() {
      if (this.currentId + 1 <= this.items.length - 1)
        this.currentId++;
    },
    setItem(id: number) {
      this.currentId = id;
    }
  }
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "18c878bd": (_ctx.activeColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__