import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d701752"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "notifications-btn-badge"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BellIcon = _resolveComponent("BellIcon")!
  const _component_NotificationsModal = _resolveComponent("NotificationsModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      type: "button",
      class: "notifications-btn",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = !_ctx.open))
    }, [
      (_ctx.$store.state.notifications.unreadNotifCount > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1))
        : _createCommentVNode("", true),
      _createVNode(_component_BellIcon)
    ]),
    (_ctx.open)
      ? (_openBlock(), _createBlock(_component_NotificationsModal, {
          key: 0,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open = false))
        }))
      : _createCommentVNode("", true)
  ], 64))
}