
import { defineComponent } from 'vue';

import PaladiumIcon from '@/ui/components/bases/icons/PaladiumIcon.vue';
import PalanarchyIcon from '@/ui/components/bases/icons/PalanarchyIcon.vue';
import ModdedIcon from '@/ui/components/bases/icons/ModdedIcon.vue';
import MinecraftIcon from '@/ui/components/bases/icons/MinecraftIcon.vue';
import FuzeEventIcon from '@/ui/components/bases/icons/FuzeEventIcon.vue';
import NavigationIndicator from './NavigationIndicator.vue';
import sleep from '../../../utils/sleep';
import { Game } from '../../../typings/games';

export default defineComponent({
  name: 'NavigationItem',
  components: {
    NavigationIndicator,
    PaladiumIcon,
    PalanarchyIcon,
    ModdedIcon,
    MinecraftIcon,
    FuzeEventIcon,
  },
  props: {
    item: { type: Object as () => Game, required: true },
  },
  data() {
    return {
      indicatorYPos: 32.5,
    };
  },
  watch: {
    $route() {
      (async () => {
        await sleep(10);
        this.updateIndicator();
      })();
    },
  },
  beforeMount() {
    this.updateIndicator();
  },
  methods: {
    updateIndicator() {
      let container = document.getElementById('main-nav__menu');
      let elements = document.getElementsByClassName('router-link-active');
      if (elements.length > 0) {
        const currElement = elements[0];

        // Set position
        const containerClientRect = container?.getBoundingClientRect();
        const currElementClientRect = currElement.getBoundingClientRect();
        this.indicatorYPos =
          currElementClientRect.top + currElementClientRect.height / 2 - (containerClientRect?.top || 0) - 17;
      }
    },
  },
});
