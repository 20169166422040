
import { defineComponent } from 'vue';
import NotificationsModal from './NotificationsModal.vue';
import BellIcon from '@/ui/components/bases/icons/BellIcon.vue';

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Notifications',
  components: { NotificationsModal, BellIcon },
  data() {
    return {
      open: false,
    };
  },
});
