import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationIndicator = _resolveComponent("NavigationIndicator")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    key: _ctx.item.name,
    title: _ctx.item.name,
    to: `/${_ctx.item.id}`,
    class: "navigation-item"
  }, {
    default: _withCtx(({ isActive }) => [
      isActive
        ? (_openBlock(), _createBlock(_component_NavigationIndicator, {
            key: 0,
            color: _ctx.item.colors.activeIndicator,
            "y-pos": _ctx.indicatorYPos
          }, null, 8, ["color", "y-pos"]))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.item.icon), {
        style: _normalizeStyle(isActive ? `fill: ${_ctx.item.colors.activeIcon};` : '')
      }, null, 8, ["style"]))
    ]),
    _: 1
  }, 8, ["title", "to"]))
}