
import { defineComponent } from 'vue';
import IndicatorIcon from '@/ui/components/bases/icons/IndicatorIcon.vue';

const __default__ = defineComponent({
  name: 'NavigationIndicator',
  components: { IndicatorIcon },
  props: {
    color: { type: String, default: '' },
    yPos: { type: Number, default: 0 },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "0372bef9": (_ctx.color),
  "a4dd3e76": (_ctx.yPos)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__