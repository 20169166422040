
import { defineComponent, ref, reactive, watchEffect, Ref } from 'vue';
import sleep from '../../utils/sleep';
import PlayIcon from '@/ui/components/bases/icons/PlayIcon.vue';
import DownloadIcon from '@/ui/components/bases/icons/DownloadIcon.vue';
import CogIcon from '@/ui/components/bases/icons/CogIcon.vue';
import Spotlight from '@/ui/components/Spotlight.vue';
import ShopCard from '@/ui/components/gamePage/ShopCard.vue';
import PalaSelect from '@/ui/components/bases/PalaSelect.vue';
import {Router, useRouter} from 'vue-router';
import {ErrorPopup, Game, GameState} from '../../typings/games';
import * as Games from '../../domain/Games';
import {requestAction, requestGetter} from "../../utils/cef";
import store from "../../store";
import ErrorModal from '@/ui/components/modals/ErrorModal.vue';

const __default__ = defineComponent({
  name: 'GamePage',
    methods: {requestAction},
  components: {
    ErrorModal,
    PlayIcon,
    DownloadIcon,
    CogIcon,
    Spotlight,
    PalaSelect,
    ShopCard,
  },
  props: {
    id: { type: String, required: true },
  },
  emits: ['openSettings'],
  /*mounted() {
    requestAction("RESIZE", {width: 1280, height: 720}, undefined);
  },*/
    mounted() {
        requestGetter("IS_LOGGED", {},(logdata: any) => {
            if (logdata.status !== 200) {
                this.$router.push("Login");
            }
        });
    },
    setup(props) {
    let showAnimation = reactive({ value: true, firstTime: true });
    let gameState: GameState = reactive({
      blogPosts: null,
      installed: false,
      launchable: false,
      startup: false,
      progress: null,
      selectedVersion: null,
      allVersions: null,
      onlineConfig: null,
      downloadingSpeed: '',
    });
    let data: Ref = ref<Game | null>(null);

    let errorModal = ref<ErrorPopup>( {
        isOpen: false,
        title: "",
        messages: [],
        primaryButton: "",
        secondaryButton: "",
        primaryAction: "",
        secondaryAction: "",
        critical: false,
    });
    const router = useRouter();

    watchEffect(async () => {
      gameState.blogPosts = [{
        id: 1,
        slug: 'la-corruption-a-envahi-paladium',
        feature_image: 'http://download.paladium-pvp.fr/Minia-trailer-V9.png',
      },
          {
          id: 2,
          slug: 'reset-faq',
          feature_image: 'http://download.paladium-pvp.fr/paladium_faq.png',
        },
          {
              id: 3,
              slug: 'paladium-fun-fight',
              feature_image: 'http://download.paladium-pvp.fr/Frame-383-2.png',
          }]

        gameState.onlineConfig = {
          shop: [
              {
                  icon: "https://download.paladium-pvp.fr/icons/titanerender.png",
                  title: "Titan",
                  subtitle: "Grade",
                  link: "https://store.paladium-pvp.fr/category/grades-v7",
                  price: "20.00€",
                  priceSubtitle: "+",
                  color: "#AAA3B2"
              },
              {
                  icon: "https://download.paladium-pvp.fr/icons/paladiumrender.png",
                  title: "Paladin",
                  subtitle: "Grade",
                  link: "https://store.paladium-pvp.fr/category/grades-v7",
                  price: "25.00€",
                  priceSubtitle: "+",
                  color: "#E8465D"
              },
              {
                  icon: "https://download.paladium-pvp.fr/icons/endiumrender.png",
                  title: "Endium",
                  subtitle: "Grade",
                  link: "https://store.paladium-pvp.fr/category/grades-v7",
                  price: "39.00€",
                  priceSubtitle: "+",
                  color: "#2912F0"
              },
              {
                  icon: "https://image.noelshack.com/fichiers/2022/16/7/1650836399-passgolden-352db27.png",
                  title: "LuckyPass",
                  subtitle: "Grade",
                  link: "https://store.paladium-pvp.fr/",
                  price: "16.00€",
                  priceSubtitle: "+",
                  color: "#F4F70E"
              },
              {
                  icon: "https://download.paladium-pvp.fr/icons/lbfinal.png",
                  isSale: true,
                  title: "Lucky Blocks",
                  subtitle: "",
                  link: "https://store.paladium-pvp.fr",
                  price: "À partir de 6.00€",
                  priceSubtitle: "+",
                  color: "#D94F4F"
              }
          ]
        }
      const w: any = window;
      if (store.state.games !== null)
        data.value = store.state.games.find((game: Game) => game.id === props.id);
      else
          console.log("store.state.games is null");
      w.displayErrors = (title: string, messages: [], primaryButton: string, secondaryButton: string, primaryAction: string, secondaryAction: string, critical = false) => {
        errorModal.value = {
          isOpen: true,
          title,
          messages,
          primaryButton,
          secondaryButton,
          primaryAction,
          secondaryAction,
          critical,
        };
      };
      if (props.id) {
        if (showAnimation.firstTime === false) {
          showAnimation.value = false;
          await sleep(1210);
        } else {
          showAnimation.firstTime = false;
        }
        requestGetter("IS_LOGGED", {},(logdata: any) => {
            if (logdata.status === 200) {
                requestGetter("GET_TOKEN", {}, (ttdata: any) => {
                    Games.getGames(ttdata.payload.jwt).then((games: Game[]) => {
                        data.value = games.find((game: Game) => game.id === props.id);
                    });
                });
            }
        });

        w.setProgress = (progress: number) => {
          gameState.progress = progress;
          if (progress >= 100) {

            requestGetter("GAME_IS_INSTALLED", {gameId: data.value.id}, (r: any) => {
              gameState.installed = r.payload.installed;
            });
            setTimeout(() => {
              gameState.progress = null;
              gameState.startup = false;
            }, 10000);
          }
        };

        w.downloadingSpeed = (speed: string) => {
          gameState.downloadingSpeed = speed;
        };

        //Reset values
        //gameState.blogPosts = null;
        gameState.installed = false;
        gameState.startup = false;
        gameState.progress = null;
        gameState.selectedVersion = null;
        gameState.allVersions = null;
        //gameState.onlineConfig = null;

        if (data.value.url) {
          //gameState.onlineConfig = await loadURL(data.value.url);
        }

        requestGetter("GAME_IS_INSTALLED", {gameId: data.value.id}, (r: any) => {
          gameState.installed = r.payload.installed;
        });
        if (data.value.versions) {
          gameState.allVersions = data.value.versions;
        }

        requestGetter("GET_OS", "os", (r: any) => {
          const isLin = (r.payload.os === "linux");
          gameState.launchable = !(isLin && !data.value.compatibleLinux);
        });
        showAnimation.value = true;

        if (props.id === "palanarchy") {
          errorModal.value = {
            isOpen: true,
            title: "Âge minimum conseillé",
            messages: ["Avant de commencer à jouer à Palanarchy, veuillez noter que ce jeu contient des éléments qui peuvent ne pas convenir aux personnes de moins de 16 ans.", "Si vous avez moins de 16 ans, nous vous conseillons d'attendre", "Merci de votre compréhension et amusez-vous bien dans ce monde de chaos !"],
            primaryButton: "J'ai compris !",
            secondaryButton: "",
            primaryAction: "CLOSE",
            secondaryAction: "",
            critical: false,
          }
        }
      }
    });

    const actionBtn = () => {
      requestGetter("IS_LOGGED", {},(logdata: any) => {
        if (logdata.status === 200) {
          requestGetter("GET_TOKEN", {}, (ttdata: any) => {
            Games.getGame(data.value.id, ttdata.payload.jwt).then((game: Game | null) => { // Update game before run ?
              if (game !== null) {
                data.value = game;
              }

              requestAction("LAUNCH_GAME", {
                gameId: data.value.id,
                url: data.value.url,
                settingsId: data.value.settingsId,
                distributionToken: data.value.distributionToken,
              }, (r: any) => {
                gameState.startup = true;
                gameState.installed = true;
              });
            });
          });
        }
      });
      /*requestAction("LAUNCH_GAME", { // THIS IS OLD METHOD TO RUN GAME
        gameId: data.value.id,
        url: data.value.url,
        settingsId: data.value.settingsId,
        distributionToken: data.value.distributionToken,
      }, (r: any) => {
        gameState.startup = true;
        gameState.installed = true;
      })*/
    };

    const selectedVersionChange = async (newVersion: any) => {
      if (data.value.id !== newVersion) {
          router.push(newVersion);
      }
    };

    return {
      data,
      errorModal,
      showAnimation,
      gameState,
      selectedVersionChange,
      actionBtn,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "17ec73d0": (_ctx.data.colors.button.default),
  "497bc2d3": (_ctx.data.colors.button.hover),
  "76ee4585": (_ctx.data.colors.button.disabled)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__