
import { defineComponent } from 'vue';
import Notifications from './notifications/Notifications.vue';
import Avatar from './Avatar.vue';
import NavigationContainer from './NavigationContainer.vue';
import NavigationItem from './NavigationItem.vue';
import * as Games from '../../../domain/Games';
import { Game } from '../../../typings/games';
import {requestGetter} from "../../../utils/cef";

export default defineComponent({
  name: 'NavigationComponent',
  components: {
    NavigationItem,
    NavigationContainer,
    Avatar,
    Notifications,
  },
  emits: ['openSettings'],
  data() {
    return {
      games: ([] as Game[]),
    };
  },
  beforeMount() {
      requestGetter("IS_LOGGED", {},(logdata: any) => {
          if (logdata.status === 200) {
              requestGetter("GET_TOKEN", {}, (ttdata: any) => {
                  Games.getGames(ttdata.payload.jwt).then((games: Game[]) => {
                      this.games = games;
                  });
              });
          }
      });
  },
});
