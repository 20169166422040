<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open">
      <div>
        <img v-if="selected.icon.length > 0" :alt="selected.value" :src="selected.icon" height="12" />
        {{ selected.value }}
      </div>
      <SelectIcon />
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div class="items-container">
        <div
          v-for="(option, i) in options"
          v-show="selected.value !== option.value"
          :key="i"
          @click="
            selected = option;
            open = false;
            $emit('change', option.id);
          "
        >
          <img v-if="option.icon.length > 0" :alt="option.value" :src="option.icon" height="12" />
          {{ option.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectIcon from './icons/SelectIcon.vue';

export default {
  name: 'SelectComponent',
  components: {
    SelectIcon,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: null,
      open: false,
    };
  },
  beforeMount() {
    if (this.id === null) {
      this.selected = this.options[0];
    } else {
      const ri = this.options.findIndex((item) => item.id === this.id);
      if (ri === -1) {
        this.selected = this.options[0];
      } else {
        this.selected = this.options[ri];
      }
    }
  },
  mounted() {
    this.$emit('change', this.selected.id);
  },
};
</script>

<style lang="scss" scoped>
.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 40px;
  line-height: 40px;
  margin-bottom: 10px;
}

.custom-select .selected {
  background-color: #ffffff;
  border-radius: 6px;
  color: #303030;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
  border: 3px solid #ffffff;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.custom-select .selected.open {
  border: 3px solid #ffffff;
  border-radius: 6px 6px 0 0;
}

.custom-select .selected:after {
  position: absolute;
  content: '';
  top: 22px;
  right: 1em;
  width: 0;
  height: 0;
  border: 3px solid transparent;
  border-color: #ffffff transparent transparent transparent;
}

.custom-select .items {
  border: 3px solid #ffffff;
  border-radius: 0 0 6px 6px;
  overflow: hidden;
  color: #303030;
  position: absolute;
  background-color: #ffffff;
  left: 0;
  right: 0;
  z-index: 15;
}

.custom-select .items-container {
  background-color: #e4e4e4;
  border-radius: 6px;

  max-height: 200px;
  overflow-y: auto;

  div {
    padding-left: 10px;
    cursor: pointer;
    user-select: none;
    overflow: hidden;

    display: flex;
    align-items: center;
    gap: 10px;

    &:hover {
      background-color: #c1c1c1;
    }
  }
}

.selectHide {
  display: none;
}
</style>
