import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37bad0fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "big-toggle" }
const _hoisted_2 = { class: "big-toggle-header" }
const _hoisted_3 = { class: "icon" }
const _hoisted_4 = { class: "big-toggle-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toggle = _resolveComponent("Toggle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.name), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.description), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Toggle, { value: _ctx.checked }, null, 8, ["value"])
      ])
    ])
  ]))
}