
import { defineComponent } from 'vue';
import {requestAction, requestGetter} from "../../utils/cef";

export default defineComponent({
  name: 'TitlebarComponent',
  props: {
    resizable: {
      type: Boolean,
      default: true,
    },
    canMinimize: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isMaximized: false,
      isWindows: false,
      isDarwin: false,
    };
  },
  async beforeMount() {
    requestGetter("GET_OS", {}, (data: any) => {
        if (data.payload.os === "windows")
            this.isWindows = true;
        else if (data.payload.os === "darwin")
            this.isDarwin = true;
        else
            this.isDarwin = false; // This is temporary, until we have a proper Linux titlebar
    });
  },
  methods: {
    minimize() {
      requestAction("MINIMIZE_WINDOW", {}, undefined);
    },
    maximize() {
      this.isMaximized = true;
      requestAction("MAXIMIZE_WINDOW", {}, undefined);
    },
    restore() {
      this.isMaximized = false;
      requestAction("RESTORE_WINDOW", {}, undefined);
    },
    close() {
      requestAction("CLOSE_WINDOW", {}, undefined);
    },
  },
});
