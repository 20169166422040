
import { defineComponent } from 'vue';
import DefaultLayout from '@/ui/layouts/Default.vue';
import EmptyLayout from '@/ui/layouts/Empty.vue';
import Notification  from "@/ui/components/bases/Notification.vue";

export default defineComponent({
  name: 'App',
  components: {
    DefaultLayout,
    EmptyLayout,
    Notification,
  },
  data() {
    return {
      notificationsInterval: null,
      errorMessage: '',
    };
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || 'default') + '-layout';
    },
  },
  mounted() {
    const w: any = window;

    w.showError = (message: string) => {
      const audio = new Audio('sounds/error.mp3');
      audio.play();

      this.errorMessage = message;
    };
  },
  methods: {
    resetError() {
      this.errorMessage = '';
    },
  },
  beforeMount() {
    //this.getNotifs();
    /*this.notificationsInterval = setInterval(() => {
      this.getNotifs();
    }, 300000); // 5 minutes*/

    // Listeners
   /* onNotifications(args => {
      this.$store.commit('setNotifications', args);
    });*/

   /* onGoTo(args => {
      this.$router.push(args);
    });

    onUserDataFetch(args => {
      this.$store.commit('setUsername', args.username);
      this.$store.commit('setSkinUrl', args.skinUrl);
    });*/
  },
 /* beforeDestroy() {
    clearInterval(this.notificationsInterval);
  },*/
  /*methods: {
    async getNotifs() {
      const notifications = await getNotifications();
      this.$store.commit('setNotifications', notifications);
    },
  },*/
});
