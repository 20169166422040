<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.41 16.542">
    <path
      d="M25.435,9.123l1.916,1.8H19.057v1.312h8.287l-1.909,1.791.9.959L29.083,12.4l.879-.827-.879-.827L26.334,8.165Z"
      transform="translate(-6.552 -2.807)"
      fill="#f63737"
    />
    <path
      d="M7.349,7.848H9.194V9.685H7.349ZM0,0V16.542H16.542V10.965H15.23V15.23H11.752V1.312H15.23V6.562h1.312V0Z"
      fill="#f63737"
    />
  </svg>
</template>

<script>
export default {
  name: 'LogoutIcon',
};
</script>
