import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-edde03fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "empty" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Titlebar = _resolveComponent("Titlebar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (this.$route.meta.titlebar)
      ? (_openBlock(), _createBlock(_component_Titlebar, {
          key: 0,
          resizable: false
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ]))
}