
import { defineComponent } from 'vue';
import Toggle from '@/ui/components/Toggle.vue';

export default defineComponent({
  name: 'BigToggle',
  components: {
    Toggle,
  },
  props: {
    name: String,
    description: String,
    checked: Boolean,
  },
});
