import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Titlebar = _resolveComponent("Titlebar")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_SettingsModal = _resolveComponent("SettingsModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (this.$route.meta.titlebar)
      ? (_openBlock(), _createBlock(_component_Titlebar, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_Navigation, {
      onOpenSettings: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openSettingsModal($event)))
    }),
    _createVNode(_component_router_view, {
      onOpenSettings: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openSettingsModal($event)))
    }),
    _createVNode(_component_SettingsModal, {
      open: _ctx.settingsModalIsOpen,
      defaultTab: _ctx.settingsModalDefaultTab,
      onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.settingsModalIsOpen = false))
    }, null, 8, ["open", "defaultTab"])
  ], 64))
}