
import { defineComponent } from 'vue';
import PalaCloseButton from './CloseButton.vue';
import SuccessIcon from './icons/Check.vue';
import ErrorIcon from './icons/Cross.vue';
import type { PropType } from 'vue';
export enum STATE {
  ERROR = 'error', SUCCESS = 'success', LOADING = 'loading'
}
export default defineComponent({
  name: 'PalaNotification',
  components: { PalaCloseButton, SuccessIcon, ErrorIcon },
  props: {
    hideIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    hideCloseButton: {
      type: Boolean,
      required: false,
      default: false
    },
    state: {
      type: String as PropType<STATE>,
      required: false,
      default: STATE.SUCCESS,
      validator: (value: STATE): boolean => Object.values(STATE).includes(value)
    }
  },
  emits: [ 'requestDismiss' ],
  data: () => ({ STATE }),
  methods: {
    capitalize: (txt: string) => txt.toLowerCase()[0].toUpperCase() + txt.slice(1),
    requestDismiss (e: Event) {
      this.$emit('requestDismiss', e);
    }
  }
});
