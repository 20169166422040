import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withDirectives as _withDirectives, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38af6e8f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "main-nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_NavigationItem = _resolveComponent("NavigationItem")!
  const _component_NavigationContainer = _resolveComponent("NavigationContainer")!
  const _component_Notifications = _resolveComponent("Notifications")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Avatar, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('openSettings', 0)))
    }),
    _createVNode(_component_NavigationContainer, { id: "main-nav__menu" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.games, (game) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: game.name
          }, [
            (game.visibility)
              ? _withDirectives((_openBlock(), _createBlock(_component_NavigationItem, _normalizeProps(_mergeProps({ key: 0 }, { item: game })), null, 16)), [
                  [_vShow, 
            _ctx.$route.path === '/paladium-ptr' || _ctx.$route.path === '/paladium'
              ? game.name === 'Paladium' && _ctx.$route.path === '/paladium-ptr'
                ? game.id === 'paladium-ptr'
                : game.name === 'Paladium' && _ctx.$route.path === '/paladium'
                ? game.id === 'paladium'
                : true
              : game.id !== 'paladium-ptr'
          ]
                ])
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_NavigationContainer, { id: "main-nav__footer" }, {
      default: _withCtx(() => [
        _createVNode(_component_Notifications)
      ]),
      _: 1
    })
  ]))
}