
import {
  getVersion,
  getAutoLaunchIsEnabled,
  getLauncherMaximizedAtStartup,
  setLauncherMaximizedAtStartup,
  setAutoLaunch,
  getTotalMem,
  getFreeMem,
  getGameJavaPath,
  getGameMem,
  getGameResolution,
  getGameStartInFullscreen,
  getLauncherStayOpen,
  setGameJavaPath,
  setGameMem,
  setGameResolution,
  setGameStartInFullscreen,
  setLauncherStayOpen,
  isWindows,
  getAccounts,
  logout,
  onUpdateAccounts,
} from '../../../electron';
import licenses from '../licenses.json';

import { defineComponent } from 'vue';
import PalaLink from '@/ui/components/bases/PalaLink.vue';
import PaladiumIcon from '@/ui/components/bases/icons/PaladiumIcon.vue';
import PalanarchyIcon from '@/ui/components/bases/icons/PalanarchyIcon.vue';
import ModdedIcon from '@/ui/components/bases/icons/ModdedIcon.vue';
import MinecraftIcon from '@/ui/components/bases/icons/MinecraftIcon.vue';
import FuzeEventIcon from '@/ui/components/bases/icons/FuzeEventIcon.vue';
import HomeIcon from '@/ui/components/bases/icons/HomeIcon.vue';
import AvatarIcon from '@/ui/components/bases/icons/AvatarIcon.vue';
import PlayCircleIcon from '@/ui/components/bases/icons/PlayCircleIcon.vue';
import RAMIcon from '@/ui/components/bases/icons/RAMIcon.vue';
import ScreenIcon from '@/ui/components/bases/icons/ScreenIcon.vue';
import JavaIcon from '@/ui/components/bases/icons/JavaIcon.vue';
import LegalIcon from '@/ui/components/bases/icons/LegalIcon.vue';
import ResolutionIcon from '@/ui/components/bases/icons/ResolutionIcon.vue';
import WindowsIcon from '@/ui/components/bases/icons/WindowsIcon.vue';
import XIcon from '@/ui/components/bases/icons/XIcon.vue';
import CogIcon from '@/ui/components/bases/icons/CogIcon.vue';
import Tab from '@/ui/components/tab/Tab.vue';
import Tabs from '@/ui/components/tab/Tabs.vue';
import Expander from '@/ui/components/Expander.vue';
import BigToggle from '@/ui/components/settings/BigToggle.vue';
import UserCard from '@/ui/components/settings/UserCard.vue';
import Toggle from '@/ui/components/Toggle.vue';
import DoubleSlider from '@/ui/components/DoubleSlider.vue';
import * as Games from '../../../domain/Games';
import index from "vuex";
import {requestAction, requestGetter, requestSetter} from "../../../utils/cef";
import {Game} from "../../../typings/games";
import store from "../../../store";

type MapTypeBool = {
  [id: string]: boolean;
}

type MapTypeAny = {
  [id: string]: any;
}

export default defineComponent({
  name: 'SettingsModal',
  components: {
    PalaLink,
    PaladiumIcon,
    PalanarchyIcon,
    ModdedIcon,
    MinecraftIcon,
    FuzeEventIcon,
    HomeIcon,
    AvatarIcon,
    PlayCircleIcon,
    RAMIcon,
    ScreenIcon,
    JavaIcon,
    LegalIcon,
    ResolutionIcon,
    WindowsIcon,
    XIcon,
    CogIcon,
    Tab,
    Tabs,
    Expander,
    BigToggle,
    UserCard,
    Toggle,
    DoubleSlider,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    defaultTab: {
      type: [String, Number],
      default: 0,
    },
  },
  emits: ['close'],
  data() {
    return {
      activeGames:([] as Game[]),
      games: Games,
      licenses,
      launcherMaximizedAtStartup: false,
      totalMem: 0,
      freeMem: 0,
      gamesMem: ({} as MapTypeAny),
      gamesResolution: ({} as MapTypeAny),
      gamesStartInFullscreen: ({} as MapTypeBool),
      gamesLauncherStayOpen: ({} as MapTypeBool),
      version: '1.0.0',
      isWindows: false,
      uuid: '',
      accounts: [{
        username: '',
        skinUrl: '',
        uuid: '',
      }],
    };
  },
  computed: {
    index() {
      return index
    },
  },
  async beforeMount() {
      requestGetter("GET_OS", undefined, (data: any) => {
          if (data.status === 200) {
              this.isWindows = data.payload.os === "windows";
          }
      });

    requestGetter("MEMORY", undefined, (data: any) => {
        if (data.status === 200) {
            this.totalMem = data.payload.totalMem;
            this.freeMem = data.payload.freeMem;
        }
    });

    requestGetter("LAUNCHER_MAXIMIZED_AT_STARTUP", undefined, (data: any) => {
        if (data.status === 200) {
            this.launcherMaximizedAtStartup = data.payload.launcherMaximizedAtStartup;
        }
    });

    requestGetter('ACCOUNTS', undefined, (data: any) => {
      if (data.status === 200) {
        this.accounts = [];
        for (const i in data.payload) {
          const ac = data.payload[i];

          this.accounts.push({
            username: ac.name,
            skinUrl: `https://crafatar.com/skins/${ac.uuid}`,
            uuid: ac.uuid,
          });
        }
      }
    });

    requestGetter("UUID", undefined, (data: any) => {
        if (data.status === 200) {
            this.uuid = data.payload.uuid;
        }
    });

      requestGetter("IS_LOGGED", {},(logdata: any) => {
          if (logdata.status === 200) {
              requestGetter("GET_TOKEN", {}, (ttdata: any) => {
                  Games.getGames(ttdata.payload.jwt).then((games: Game[]) => {
                      games.forEach((game: Game) => {
                          if (game.visibility) {
                              this.activeGames.push(game);

                              requestGetter("GAME_SETTINGS", {id: game.settingsId}, (data: any) => {
                                  if (data.status === 200) {
                                      this.gamesMem[game.settingsId] = data.payload.memory;
                                      this.gamesResolution[game.settingsId] = data.payload.resolution;
                                      this.gamesStartInFullscreen[game.settingsId] = data.payload.startInFullscreen;
                                      this.gamesLauncherStayOpen[game.settingsId] = data.payload.launcherStayOpen;
                                  }
                              });
                          }
                      })
                  });
              });
          }
      });
  },
  methods: {
    close() {
      this.$emit('close');
    },
    setLauncherMaximizedAtStartup(checked: boolean) {
      requestSetter("LAUNCHER_MAXIMIZED_AT_STARTUP", {launcherMaximizedAtStartup: checked}, (data: any) => {
          if (data.status === 200) {
              this.launcherMaximizedAtStartup = checked;
          }
      });
    },
    updateSettings(settingsId: string) {
      requestSetter("GAME_SETTINGS", {
        id: settingsId,
        memory: this.gamesMem[settingsId],
        resolution: this.gamesResolution[settingsId],
        startInFullscreen: this.gamesStartInFullscreen[settingsId],
        launcherStayOpen: this.gamesLauncherStayOpen[settingsId],
      }, undefined);
    },
    setGameStartInFullscreen(settingsId: string, value: boolean) {
      this.gamesStartInFullscreen[settingsId] = value;
      this.updateSettings(settingsId);
    },
    setLauncherStayOpen(settingsId: string, value: boolean) {
      this.gamesLauncherStayOpen[settingsId] = value;
      this.updateSettings(settingsId);
    },
    addAccount() {
        requestGetter("GET_LOGIN_LINK", undefined, (data: any) => {
            requestAction("POPUP",
                {
                    title: "Connexion",
                    url: data.payload.link,
                    width: 536,
                    height: 693,
                }, undefined);
            //window.location.href = data.payload.link;
        });
    }
  },
});
